<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Akun Perkiraan</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-jurnal-perkiraan"
                      >Akun Perkiraan
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row mt-2">
              <div class="col-12">
                <div class="title">Detail Akun Perkiraan</div>
                <div class="sub-title">Tambah informasi yang diperlukan</div>
              </div>
            </div>
            <hr />

            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Tipe Akun </label>
                  <Select2
                    v-model="formData.id_tipe"
                    :class="{ 'is-invalid': formError && formError.id_tipe }"
                    :options="tipeAkun"
                    placeholder="List Tipe Akun"
                    @change="formChange('id_tipe')"
                    @select="formChange('id_tipe')"
                  />
                  <div class="form-error" v-if="formError && formError.id_tipe">
                    {{ formError.id_tipe }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Nama Akun </label>
                  <input
                    type="text"
                    class="form-control"
                    @keyup="formChange('nama')"
                    v-model="formData.nama"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    id="inputAddress2"
                    placeholder="Masukkan Nama Akun"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="inputAddress2">Status :</label>
                  <div class="form-group edit-vendor">
                    <Select2
                      v-model="formData.status"
                      :class="{ 'is-invalid': formError && formError.status }"
                      :options="statusCompany"
                      placeholder="Status"
                      :settings="{ minimumResultsForSearch: -1 }"
                      @change="formChange('status')"
                      @select="formChange('status')"
                    />
                    <div
                      class="form-error"
                      v-if="formError && formError.status"
                    >
                      {{ formError.status }}
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Parent Akun</label>
                  <Select2
                    v-model="formData.id_parent"
                    :class="{ 'is-invalid': formError && formError.id_parent }"
                    :options="subAkun"
                    placeholder="List Sub Akun"
                    :settings="{
                      minimumResultsForSearch: 3,
                      templateResult: formatState,
                    }"
                    @change="formChange('id_parent', $event)"
                    @select="formChange('id_parent', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_parent"
                  >
                    {{ formError.id_parent }}
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="inputAddress2">Departemen :</label>
                  <div class="form-group edit-vendor">
                    <Select2
                      v-model="formData.id_divisi"
                      :class="{
                        'is-invalid': formError && formError.id_divisi,
                      }"
                      :disabled="!enableDivisi"
                      :options="optionDepartement"
                      placeholder="Departemen"
                      @change="formChange('id_divisi')"
                      @select="formChange('id_divisi')"
                    />
                    <div
                      class="form-error"
                      v-if="formError && formError.id_divisi"
                    >
                      {{ formError.id_divisi }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div v-if="enableSaldo">
              <div class="row mt-2">
                <div class="col-12">
                  <div class="title">Saldo Awal</div>
                  <div class="sub-title">
                    Saldo awal pada akun perkiraan,
                    <span class="text-danger font-italic"
                      >Isi semua form dibawah ini jika ingin menyimpan data
                      Saldo Awal</span
                    >
                  </div>
                </div>
              </div>
              <hr />
              <div class="row mt-3">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="" for="inlineFormInputGroupUsername"
                          >Nilai :</label
                        >
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Rp</div>
                          </div>
                          <input
                            :disabled="validated == 1 || formSaldo.has_saldo"
                            type="text"
                            class="form-control text-right"
                            :model-modifiers="{ number: true }"
                            v-model.lazy="formSaldo.nilai"
                            placeholder="Nilai"
                            v-money="configMoney"
                            :class="{
                              'is-invalid': formErrorSaldo && formErrorSaldo.nilai,
                            }"
                            @keyup="formChange('nilai', 'saldo')"
                          />
                        </div>
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.nilai"
                        >
                          {{ formErrorSaldo.nilai }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="inputAddress2">Akun Beban</label>
                        <Select2
                          v-model="formSaldo.beban"
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          :options="optionAkunBeban"
                          placeholder="List Akun"
                          :settings="{ templateResult: formatState }"
                          @change="formChange('beban', 'saldo')"
                          @select="formChange('beban', 'saldo')"
                          :class="{
                              'is-invalid': formErrorSaldo && formErrorSaldo.beban,
                            }"
                        />
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.beban"
                        >
                          {{ formErrorSaldo.beban }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="inputAddress2">Per Tanggal </label>
                        <Datepicker
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          :autoApply="true"
                          v-model="formSaldo.tanggal"
                          :format="format"
                          :enableTimePicker="false"
                          locale="id-ID"
                          selectText="Pilih"
                          cancelText="Batal"
                          @update:modelValue="changeDate"
                          placeholder="Pilih Tanggal"
                          :class="{
                            'is-invalid': formErrorSaldo && formErrorSaldo.tanggal,
                          }"
                        ></Datepicker>
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.tanggal"
                        >
                          {{ formErrorSaldo.tanggal }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="inputAddress2">Departemen :</label>
                        <div class="form-group edit-vendor">
                          <Select2
                            v-model="formSaldo.id_divisi"
                            :class="{
                              'is-invalid': formErrorSaldo && formErrorSaldo.id_divisi,
                            }"
                            :options="optionDepartement"
                            placeholder="Departemen"
                            @change="formChange('id_divisi', 'saldo')"
                            @select="formChange('id_divisi', 'saldo')"
                          />
                          <div
                            class="form-error"
                            v-if="formErrorSaldo && formErrorSaldo.id_divisi"
                          >
                            {{ formErrorSaldo.id_divisi }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Sumber Saldo</label>
                        <input
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          type="text"
                          class="form-control"
                          v-model="formSaldo.sumber"
                          @keyup="formChange('sumber', 'saldo')"
                          :class="{
                            'is-invalid': formErrorSaldo && formErrorSaldo.sumber,
                          }"
                          placeholder="Sumber Saldo"
                        />
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.sumber"
                        >
                          {{ formErrorSaldo.sumber }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="catatan">Catatan:</label>
                        <textarea
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          type="text"
                          class="form-control"
                          id="catatan"
                          v-model="formSaldo.keterangan"
                          @keyup="formChange('keterangan', 'saldo')"
                          placeholder="Masukkan Catatan"
                        ></textarea>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { ref } from "vue";
import $ from "jquery";
import moment from "moment";
import { checkRules, cksClient, showAlert } from "../../../helper";
import { VMoney } from "v-money";
import {
  get_AkunList,
  get_AkunTipeList,
  post_AkunSave,
} from "../../../actions/akun_perkiraan";
import { get_ListDivisi } from "../../../actions/master";
// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    money: VMoney,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  data() {
    return {
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      tipeAkun: [],
      subAkun: [],
      optionDepartement: [],
      optionAkunBeban: [],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      accessDepartement: cksClient().get("_account").akses_departement,
      formData: {
        id: "",
        nama: "",
        id_company: cksClient().get("_account").id_company,
        id_tipe: "",
        id_divisi: "",
        id_parent: "",
        status: "aktif",
      },
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        id_tipe: {
          required: true,
        },
        id_divisi: {
          required: false,
        },
        status: {
          required: true,
        },
      },
      formSaldo: {
        nilai: "",
        keterangan: "",
        tanggal: "",
        sumber: "",
        beban: "",
        id_divisi: "",
        has_saldo: false,
      },
      saldoRules: {
        nilai: {
          required: false,
        },
        tanggal: {
          required: false,
        },
        sumber: {
          required: false,
        },
        beban: {
          required: false,
        }
      },
      formErrorSaldo: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      enableSaldo: true,
      enableDivisi: true,
    };
  },

  created() {
    if (this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formData.id_divisi = data[0];
    }
    this.getListAkun();
    // this.getSubAkun()
    this.getTipe();
    this.getDivisi();
  },

  mounted() {
    $("#tableDepartment").DataTable({
      info: false,
    });
  },
  methods: {
    getTipe() {
      get_AkunTipeList((res) => {
        this.tipeAkun = res.list;
      });
    },
    getListAkun(){
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "",
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }

          this.optionAkunBeban = data;
        }
      );
    },
    getSubAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
          sub_akun: true,
          tipe: this.formData.id_tipe,
        },
        (res) => {
          const { list } = res;
          this.subAkun = [];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            this.subAkun.push({
              id: element.id,
              text: element.nama,
              saldo: element.saldo_awal,
              id_divisi: element.id_divisi,
              sub_level: element.sub_level,
              nama_divisi: element.nama_divisi ? element.nama_divisi : "-",
            });
          }
        }
      );
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        this.optionDepartement = res.list;
      });
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.id +
          "<div>" +
          state.nama_divisi +
          "</div></div></div>"
      );
      return $state;
    },
    async formChange(key, tipe = '') {
      if (tipe == 'saldo') {
        if(this.formSaldo.nilai != "" && this.formSaldo.nilai != 0 && this.formSaldo.nilai != "0") {
          this.saldoRules.beban.required = true;
          this.saldoRules.tanggal.required = true;
          this.saldoRules.sumber.required = true;
        } else {
          this.saldoRules.beban.required = false;
          this.saldoRules.tanggal.required = false;
          this.saldoRules.sumber.required = false;
        }
        if (this.saldoRules[key]) {
          this.saldoRules[key].changed = true;
        }
        let check = await checkRules(this.saldoRules, this.formSaldo);
        this.formErrorSaldo = check.error;
      } else {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
        if (key == "id_tipe" && this.formData[key]) {
          this.getSubAkun();
        } else if (key === "id_tipe") {
          this.subAkun = [];
        }
        let check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },

    changeDate(data) {
      this.formSaldo.tanggal = moment(data).format("YYYY-MM-DD");
      this.formChange('tanggal', 'saldo')
    },
    postData() {
      this.isSubmit = true;
      var data = {...this.formData}
      data.saldo = this.formSaldo
      post_AkunSave(
        data,
        (res) => {
          var msg =
            res.response_code === 201
              ? "Data berhasil disimpan"
              : "Data berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({ name: "EstimatedAccount" });
            },
          });
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }

      for (const key in this.saldoRules) {
        if (Object.hasOwnProperty.call(this.saldoRules, key)) {
          this.saldoRules[key].changed = true;
        }
      }

      let check = await checkRules(this.rules, this.formData);
      this.formError = check.error;

      let checkSaldo = await checkRules(this.saldoRules, this.formSaldo);
      this.formErrorSaldo = checkSaldo.error;
      if (check.success && checkSaldo.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
